import { MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import { baseLng, languages } from '../../../variables';
import { useTranslation } from 'react-i18next';
import InfoPanel from '../../Interface/MainPanel/InfoPanel';

const LanguageSwitcher = React.memo(() => {
    const [selectedLng, setSelectedLng] = useState(localStorage.getItem("lang") === null ? baseLng : localStorage.getItem("lang"));
    const { i18n } = useTranslation();
    //const radioInfo = JSON.parse(localStorage.getItem('SelectedRadio'));
    const { t } = useTranslation();

    function switchHandler(e) {
        localStorage.setItem("lang", e.target.value);
        setSelectedLng(e.target.value);
        i18n.changeLanguage(e.target.value);
        InfoPanel(t);
    }

    return (
        <div>
            <Select
                style={{ fontSize: '40%' }}
                onChange={switchHandler}
                value={selectedLng}
                sx={{
                    boxShadow: 'none',
                    '.MuiSelect-select': { paddingLeft: '0.5vw', paddingTop: '0', paddingBottom: '0' },
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 }
                }}
            >
                {
                    languages.map((language, index) => (
                        <MenuItem
                            key={index}
                            value={language}
                        >
                            {t(language)}
                        </MenuItem>
                    ))
                }
            </Select>
        </div>
    );
});

export default LanguageSwitcher;