import React, { useEffect, useState } from "react";
import "../../css-files/RadioListButton.css"
import "./Favorite.css"
import { data } from "../../SVR_Radio";
import Flag from "react-flagkit";

export default function FavoriteList({ favoriteList, setRadioStation }) {
    const [favorite, setFavoriteList] = useState(favoriteList);

    useEffect(() => {
        setFavoriteList(JSON.parse(localStorage.getItem('favorite')));
    }, [localStorage.getItem('favorite')]);

    return data.map((info) => {
        if (favorite?.includes(info?.Id))
            return (
                <div
                    key={info.Id + "_favoritediv"}
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "stretch",
                        flexWrap: "nowrap",
                        flexDirection: "row"
                    }}>
                    <button
                        key={info.Name + "_favoritebtn"}
                        className="btn btn--stripe"
                        style={{
                            boxShadow: "none",
                            margin: "1%",
                            padding: "10%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            alignContent: "center",
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            flex: "1"
                        }}
                        onClick={() => setRadioStation(info.Id)}
                    >
                        {info.Name}
                        <Flag key={info.Id + "_favoriteflag"} country={info.Country} className="btn-img-country" />
                    </button>
                </div>
            );
        else return <></>
    });
}