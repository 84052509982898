import { Col, Container, Form, Image, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import React from "react";
import { BiErrorCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { SVRLogoImage } from "../../../../variables";
import severin from "../../../image/severin.jpg";
import palamarchuk from "../../../image/palamarchuk.jpg";
import { BiLogoYoutube, BiLogoFacebookCircle, BiLogoInstagram, BiLogoDiscord, BiLogoLinkedinSquare, BiLogoTelegram } from "react-icons/bi";

function AboutPanel(props) {
    const { t } = props;

    return (
        <Modal
            animation={true}
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton style={{ borderTop: "4px solid #666", borderLeft: "4px solid #666", borderRight: "4px solid #666" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div>{t('about')}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ borderLeft: "4px solid #666", borderRight: "4px solid #666", padding: '2vh 0' }}>
                <Form style={{ textAlign: "center" }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: '0 1vw'
                    }} >
                        <Image style={{ paddingBottom: '1vh' }} src={SVRLogoImage} />
                        <h4 style={{ textWrap: 'balance' }}>
                            <span style={{ fontWeight: 'bold', fontFamily: "CrosshatcherD" }}>SVR Radio</span> - {t("svrradio-about")}.
                        </h4>
                    </div>
                    <div key='about-text-line1' style={{ borderTop: '1px solid #dee2e6' }} />
                    <div key='main-page-div' style={{ margin: '1vh 0' }}>
                        <h4 key='main-page-h4'>{t('mainpage')}</h4>
                        <a href="http://www.specsoft.org.ua/" target="blank" key='about-text-project'>
                            http://www.specsoft.org.ua/
                        </a>
                    </div>
                    <div key='about-text-line2' style={{ borderTop: '1px solid #dee2e6' }} />
                    <div key='developers-panel' style={{ margin: '1vw 1vh' }}>
                        <h4 key='dev-about1' style={{ fontWeight: 'bold' }}>{t('developers')}</h4>
                        <Container key='developers-text' style={{ margin: '1vw 1vh', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-around', alignItems: 'stretch' }}>
                            <Row>
                                <Col className="developer" key='dev-about1'>
                                    <Image key='dev-about1-img' src={severin} />
                                    <h5 key='dev-about1-h5'>{t('severin')}</h5>
                                    <div key='dev-about1-div'>{t('severin-text')}</div>
                                    <div key='dev-about2-div-social'>
                                        <a href="https://www.facebook.com/profile.php?id=100011091706239" target="blank"><BiLogoFacebookCircle /></a>
                                        <a href="https://www.instagram.com/severin.aleksey/" target="blank"><BiLogoInstagram /></a>
                                    </div>
                                </Col>
                                <Col className="developer" key='dev-about2'>
                                    <Image key='dev-about2-img' src={palamarchuk} />
                                    <h5 key='dev-about2-h5'>{t('palamarchuk')}</h5>
                                    <div key='dev-about2-div'>{t('palamarchuk-text')}</div>
                                    <div key='dev-about2-div-social'>
                                        <a href="https://www.youtube.com/@CapitanMaxwell" target="blank"><BiLogoYoutube /></a>
                                        <a href="https://www.facebook.com/capitanmaxwell" target="blank"><BiLogoFacebookCircle /></a>
                                        <a href="https://www.instagram.com/capitan_maxwell" target="blank"><BiLogoInstagram /></a>
                                        <a href="https://www.discord.gg/TdYfcHb" target="blank"><BiLogoDiscord /></a>
                                        <a href="https://www.linkedin.com/in/serhiipalamarchuk/" target="blank"><BiLogoLinkedinSquare /></a>
                                        <a href="https://www.t.me/CapitanMaxwellYT" target="blank"><BiLogoTelegram /></a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{ borderBottom: "4px solid #666", borderLeft: "4px solid #666", borderRight: "4px solid #666" }}>
            </Modal.Footer>
        </Modal>
    );
}

const About = React.memo((props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { t } = useTranslation();

    return (
        <>
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id={`tooltip-bottom`}>
                        {t('about')}
                    </Tooltip>
                }
            >
                <button style={{ boxShadow: 'none', border: 'none', background: 'none', padding: '0', fontFamily: 'none', fontSize: '0', marginRight: '0.3vw', marginTop: 'auto', marginBottom: '0' }} onClick={() => setModalShow(true)}>
                    <BiErrorCircle style={{ width: '1.5vw', height: '-webkit-fill-available' }} />
                </button>
            </OverlayTrigger>

            <AboutPanel
                t={t}
                show={modalShow}
                onHide={() => setModalShow(false)}
                style={{ border: "4px solid #666" }}
            />
        </>
    );
})

About.prototype = {
}

export default About;