import React from "react";
import '../../css-files/TopBarButton.css'
import LanguageSwitcher from "../../Tools/LanguageSwitcher/LanguageSwitcher";
import Donate from "./Elements/Donate";
import Help from "./Elements/Help";
import About from "./Elements/About";
// import SingIn from "./Elements/SingIn";
// import SingUp from "./Elements/SingUp";

function TopBar() {
    return (
        <div className="topbar" id="topbar">
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row'
            }}>
                <Donate />
                <Help />
            </div>
            <div className="svrradio">
                <About />
                SVR Radio
                <LanguageSwitcher />
            </div>
            {/* {false ? <div>enter</div> : <SingIn />}
            {false ? <div>enter</div> : <SingUp />} */}
        </div>
    );
};

export default TopBar;