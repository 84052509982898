import React, { useEffect, useState } from "react";
import '../../SVR_Radio.css';
import 'bootstrap/dist/css/bootstrap.css';
import RadioList from "./RadioList/RadioList";
import { Player, SetRadioStation } from "../../Tools/Player";
import { Nav } from "react-bootstrap";
import InfoPanel from "./InfoPanel";
import FavoriteList from "../../Tools/Favorite/FavoriteList";
import packageJSON from "../../../../package.json";
import { useTranslation } from "react-i18next";
import { data } from "../../SVR_Radio";
// import RadioLoadImg from "../../image/load-radio.gif";
// import { Image } from "react-bootstrap";
//import AudioSpectrum from 'react-audio-spectrum2';

function MainPanel() {
    const [isChangeList, setChangeList] = useState("radiolist");
    const [favoriteList, setFavoriteList] = useState(JSON.parse(localStorage.getItem('favorite')));
    const [currentRadio, setCurrentRadio] = useState(JSON.parse(localStorage.getItem('SelectedRadio')));
    const { t } = useTranslation();
    const navLink = {
        border: "2px solid #666",
        alignItems: "center",
        display: "flex",
        padding: "5%",
        justifyContent: "center",
        wordBreak: "break-all",
        letterSpacing: "-1px",
        whiteSpace: "normal",
        fontSize: "small",
    };

    const setRadioStation = (id) => {
        SetRadioStation(id)
        setCurrentRadio(JSON.parse(localStorage.getItem('SelectedRadio')));
    }

    const favoriteAddRemove = () => {
        var id = JSON.parse(localStorage.getItem('SelectedRadio')).Id;
        var listOfObjects = [];
        var favoriteList = JSON.parse(localStorage.getItem('favorite'));

        if (favoriteList == null) {
            listOfObjects.push(id)
            localStorage.setItem('favorite', JSON.stringify(listOfObjects));
        }

        if (!favoriteList?.includes(id))
            listOfObjects = [...favoriteList, id];
        else {
            listOfObjects = favoriteList;
            for (let i = 0; i < listOfObjects.length; i++) {
                if (favoriteList.includes(id)) {
                    listOfObjects = listOfObjects.filter(c => c !== id);
                }
            }
        }

        localStorage.setItem('favorite', JSON.stringify(listOfObjects));
        setFavoriteList(JSON.parse(localStorage.getItem('favorite')));
    }

    //const [close, isClose] = useState(false);

    // const openMenuFilter_Icon = () => {
    //     isClose(!close);
    // }

    useEffect(() => {
        if (!localStorage.getItem('favorite'))
            localStorage.setItem('favorite', "[]")

        setFavoriteList(JSON.parse(localStorage.getItem('favorite')));
        setCurrentRadio(JSON.parse(localStorage.getItem('SelectedRadio')));
    }, [localStorage.getItem('SelectedRadio'), localStorage.getItem('favorite')])

    function setClickList() {
        if (isChangeList === "radiolist") {
            return <RadioList setRadioStation={setRadioStation} />
        }
        else if (isChangeList === "favoritelist") {
            return <FavoriteList favoriteList={favoriteList} setRadioStation={setRadioStation} />
        }
    }

    const Rewind = (id, isFront) => {
        if (isFront) {
            if (id === data.length) SetRadioStation(data[0].Id);
            else SetRadioStation(id + 1);
        }
        else {
            if (id === 1) SetRadioStation(data.length);
            else SetRadioStation(id - 1);
        }

        setCurrentRadio(JSON.parse(localStorage.getItem('SelectedRadio')));
        return currentRadio;
    };

    return (
        <div className='mainpanel'>
            <div className='rafiolistpanel'>
                <Nav justify variant="pills" defaultActiveKey="radiolist" style={{ overflow: "visible", marginBottom: "2%", marginTop: "1%" }}>
                    <Nav.Item style={{ display: "flex" }}>
                        <Nav.Link onClick={() => setChangeList("radiolist")} eventKey="radiolist"
                            style={navLink}
                            className="shadow-none btn btn-animation"
                        >
                            {t("radiolist")}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item style={{ display: "flex" }}>
                        <Nav.Link onClick={() => setChangeList("favoritelist")} eventKey="favoritelist"
                            style={navLink}
                            className="shadow-none btn btn-animation"
                        >
                            {t("favoritelist")}
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <div className="radiolist" key={isChangeList}>
                    {setClickList()}
                </div>
            </div>
            <div className="radiopanel">
                <div
                    style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                        alignItems: "stretch",
                        position: "relative"
                    }}>
                    <InfoPanel handleClick={favoriteAddRemove} radioInfo={currentRadio} favorite={favoriteList} />
                    <div className="currentWeek">{packageJSON.version}</div>
                </div>
                <div className="mediabuttonpanel">
                    <Player Rewind={Rewind} />
                </div>
            </div>
        </div>
    );
};

export function CurrentWeekNumber() {
    var currentDate = new Date();
    var startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));

    var weekNumber = Math.ceil(days / 7);

    // Display the calculated result      
    return (weekNumber);
}

export default MainPanel;