import React from 'react';
import './index.css';
import SVRRadio from './SVR_Radio/SVR_Radio';
import reportWebVitals from './reportWebVitals';
import "./SVR_Radio/fonts/CrosshatcherD.ttf";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { baseLng } from './variables';
import { createRoot } from 'react-dom/client';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ua: {
        translation: require('./SVR_Radio/Tools/LanguageSwitcher/Languages/ua.json')
      },
      pl: {
        translation: require('./SVR_Radio/Tools/LanguageSwitcher/Languages/pl.json')
      },
      en: {
        translation: require('./SVR_Radio/Tools/LanguageSwitcher/Languages/en.json')
      },
    },
    lng: localStorage.getItem("lang") === null ? baseLng : localStorage.getItem("lang"),
    fallbackLng: baseLng,

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const root = createRoot(document.getElementById('root'));
root.render(<SVRRadio />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
