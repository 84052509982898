import React, { useState } from 'react';
import './SVR_Radio.css';
import MainPanel, { CurrentWeekNumber } from './Interface/MainPanel/MainPanel';
import TopBar from './Interface/TopBar/TopBar';
import loadGif from './image/load.gif';
import { SetRadioStation } from './Tools/Player';
import { Image } from 'react-bootstrap';
import packageJSON from "../../package.json";

export var data = require('./Interface/MainPanel/RadioList/data/data.json');

const SVR_Radio = () => {
  const [loadpage, setLoadPage] = useState(true);

  setTimeout(() => {
    setLoadPage(false)
    if(!localStorage.getItem('SelectedRadio')) SetRadioStation(data[0].Id);
  }, 2775)

  console.log(`SVR Radio vesion: ${packageJSON.version}\nActual version date: 0.0.23.${CurrentWeekNumber()}`)

  return (
    <div className="SVR_Radio">
      {loadpage ?
        <Image src={loadGif} style={{display: "flex", margin: "auto"}}/>
        :
        <>
          <TopBar />
          <MainPanel />
        </>
      }
    </div>
  );
}

export default SVR_Radio;