import React from "react";
import { Dropdown, Form, Image } from "react-bootstrap";
import Patreon from "../../../image/Patreon.svg";
import PayPal from "../../../image/paypal.svg";
//import Donatello from "../../../image/Donatello.svg";
import { Translation } from "react-i18next";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        className="btn btn-animation btn--radius"
        variant="primary"
        style={{ boxShadow: 'none' }}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </button>
));

const Donate = React.memo((props) => {
    return (
        <>
            <Dropdown align={{ lg: "start" }}>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    <Translation>
                        {
                            (t) => <div id="donate-dropdown-text">{t("donate")}</div>
                        }
                    </Translation>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ border: "2px solid #666", position: 'fixed', top: 'auto', left: 'auto', right: 'auto', bottom: 'auto' }}>
                    <h5 style={{ borderBottom: "2px solid #666", padding: "5%", wordBreak: "break-word", textAlign: "center" }}>
                        <Translation>
                            {
                                (t) => <div id="donate-support">{t("supportproj")}</div>
                            }
                        </Translation>
                    </h5>
                    <Form className="Donate" style={{ padding: "2%" }}>
                        <a className="btn btn--stripe btn--radius" href="https://www.paypal.com/donate/?hosted_button_id=U2PWV7WG97V2J" target="blank">
                            <Image src={PayPal} alt="paypal" />                        
                        </a>
                        <a className="btn btn--stripe btn--radius" href="https://www.patreon.com/SVRRadio" target="blank">
                            <Image src={Patreon} alt="patreon" />
                        </a>
                        {/* <a className="btn btn--stripe btn--radius" href="https://www.donatello.to/" target="blank">
                            <Image src={Donatello} alr="donatello" />
                        </a> */}
                    </Form>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
})

Donate.prototype = {
}

export default Donate;