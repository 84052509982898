import React from "react";
import "../../../css-files/RadioListButton.css";
import { data } from "../../../SVR_Radio";
import Flag from "react-flagkit";

export default function RadioList({ setRadioStation }) {
    const displaydata = data.map((info) => {
        return (
            <div
                key={info.Id}
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "stretch",
                    flexWrap: "nowrap",
                    flexDirection: "row"
                }}>
                <button
                    key={info.Name}
                    className="btn btn--stripe btn-list"
                    onClick={() => setRadioStation(info.Id)}
                >
                    {info.Name}
                    <Flag key={info.Id + "_flag"} country={info.Country} className="btn-img-country" />
                </button>
            </div>
        );
    })
    return (
        <>
            {displaydata}
        </>
    );
};