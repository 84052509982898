import React, { useState, useEffect } from "react";
import Play from "../image/play.svg";
import Pause from "../image/pause.svg";
import Rewind_Back from "../image/rewind-back.svg";
import Rewind_Front from "../image/rewind-front.svg";
import volumeOff from "../image/volumeOff.svg";
import volumeOn from "../image/volumeOn.svg";
import { data } from "../SVR_Radio";
import { Image } from "react-bootstrap";
import ReactAudioPlayer from "react-audio-player";

function SetRadioStation(Id) {
  data.forEach(c => c.Id === Id ? localStorage.setItem("SelectedRadio", JSON.stringify(c)) : null)
}

// const AudioURL = (radioData) => {
//   var defaultUrl = data[0].URL;
//   var urlAudio = defaultUrl;
//   if (radioData != null)
//     if (radioData.URL !== null || radioData.URL !== undefined || radioData.URL !== "") {
//       urlAudio = radioData.URL;
//     }

//   return urlAudio;
// }

function Player({ Rewind }) {
  const [volumeSound, setVS] = useState(0.5);
  const [radioData, setRadioData] = useState(JSON.parse(localStorage.getItem('SelectedRadio')));
  const [playerHtml, setPlayerHtml] = useState(null);
  const [isPause, setIsPause] = useState(true)
  const [isMuted, setIsMuted] = useState(volumeSound > 0 ? false : true);

  useEffect(() => {
    if (!localStorage.getItem('isPause'))
      localStorage.setItem("isPause", true);
    // else {      
    //   setIsPause(JSON.parse(localStorage.getItem("isPause")));
    // };

    if (!localStorage.getItem('Volume'))
      localStorage.setItem("Volume", volumeSound)
    else
      setVS(JSON.parse(localStorage.getItem('Volume')));

    if (!localStorage.getItem('isMuted')) {
      localStorage.setItem('isMuted', isMuted);
    }

    if (!localStorage.getItem('SelectedRadio'))
      SetRadioStation(data[0].Id);
    else setRadioData(JSON.parse(localStorage.getItem('SelectedRadio')));

    if (playerHtml)
      playerHtml.volume = volumeSound;

    localStorage.setItem("isPause", !isPause);

  }, [isPause, playerHtml, localStorage.getItem('isPause'), localStorage.getItem('Volume'), localStorage.getItem('isMuted')]);

  const changeVolume = (volumeValue) => {
    if (!playerHtml)
      return;

    if (parseFloat(volumeValue) > 0) {
      setIsMuted(false);
    }

    setVS(parseFloat(volumeValue));
    playerHtml.volume = volumeValue
    localStorage.setItem("Volume", volumeValue)

    if (JSON.parse(localStorage.getItem('Volume')) > 0) {
      setIsMuted(false);
      localStorage.setItem('isMuted', false)
    }
    else {
      setIsMuted(true);
      localStorage.setItem('isMuted', true)
    }
  };

  const ChangeImageMute = () => {
    if (!playerHtml)
      return;

    const changeMute = () => {
      if (JSON.parse(localStorage.getItem('Volume')) > 0 && isMuted) {
        playerHtml.volume = 0;
        setVS(0);
      }
      else if (JSON.parse(localStorage.getItem('Volume')) > 0 && !isMuted) {
        playerHtml.volume = volumeSound;
        setVS(JSON.parse(localStorage.getItem('Volume')));
      }
    }

    return (
      <button className="Rewind" onClick={() => {
        if (JSON.parse(localStorage.getItem('Volume')) > 0) {
          setIsMuted(!isMuted);
          localStorage.setItem('isMuted', !isMuted);
        }
        changeMute();
      }}>
        {
          isMuted ?
            (<Image src={volumeOff} style={{ width: "13vw" }} alt="volumeOff" />)
            :
            (<Image src={volumeOn} style={{ width: "13vw" }} alt="volumeOn" />)
        }
      </button>
    )
  };

  const PlayPause = () => {
    if (!playerHtml)
      return;

    const startStop = () => {
      // if (localStorage.getItem("isPause"))
      //   setIsPause(JSON.parse(localStorage.getItem("isPause")));

      setIsPause(c => !c)

      playerHtml.volume = volumeSound;
      playerHtml.src = JSON.parse(localStorage.getItem('SelectedRadio')).URL;
      playerHtml.pause()
      playerHtml.load()
      if (localStorage.getItem("isPause") === "true") {
        try {
          playerHtml.pause()
        } catch { }
      }
      else if (localStorage.getItem("isPause") === "false") {
        try {
          playerHtml.play()
        } catch { }
      }

    }

    return (
      <button className="Player" onClick={() => { startStop() }}>
        {isPause === false ?
          <Image src={Pause} style={{ width: "10vw", height: "25vh" }} alt="stop" />
          :
          <Image src={Play} style={{ width: "10vw", height: "25vh" }} alt="play" />
        }
      </button>
    );
  };

  function ReloadSation() {
    playerHtml.volume = volumeSound;
    playerHtml.src = JSON.parse(localStorage.getItem('SelectedRadio')).URL;
    playerHtml.pause()
    playerHtml.load()
    if (isPause === true) {
      try {
        playerHtml.pause()
      } catch { }
    }
    else {
      try {
        playerHtml.play()
      } catch { }
    }
  }


  const RewindBack = () => {
    return (
      <button className="Rewind" onClick={() => { setRadioData(Rewind(JSON.parse(localStorage.getItem('SelectedRadio')).Id, false)); ReloadSation(); }}>
        <Image src={Rewind_Back} style={{ width: "10vw", height: "25vh" }} alt="rewind_back" />
      </button>
    );
  };

  const RewindFront = () => {
    return (
      <button className="Rewind" onClick={() => { setRadioData(Rewind(JSON.parse(localStorage.getItem('SelectedRadio')).Id, true)); ReloadSation(); }}>
        <Image src={Rewind_Front} style={{ width: "10vw", height: "25vh" }} alt="rewind_front" />
      </button>
    );
  };

  return (
    <>
      <ReactAudioPlayer volume={volumeSound} ref={(element) => { setPlayerHtml(element?.audioEl?.current) }} id="player" />
      <div style={{ border: "2px solid rgb(102, 102, 102)", borderRadius: "4.25rem", height: "85%", display: "flex", alignItems: "center", marginLeft: "1%" }}>
        <RewindBack />
        <PlayPause />
        <RewindFront />
      </div>
      <div style={{ margin: "auto 1% auto auto", width: "50%", display: "flex", alignItems: "center", border: "2px solid rgb(102, 102, 102)", borderRadius: "4.25rem", height: "85%", justifyContent: "center" }}>
        <ChangeImageMute />
        <input onChange={e => changeVolume(e.target.value)} value={volumeSound} type="range" min="0" max="1" step="0.001" className="volume-slider" id="volume"></input>
      </div >
    </>
  );
}

export { Player, SetRadioStation }