import { Form, Modal } from "react-bootstrap";
import React, { useState } from "react";
import emailjs from '@emailjs/browser';
import { BiStar } from "react-icons/bi";
import { useTranslation } from "react-i18next";

function HelpPanel(props) {
    const [subject, setSubject] = useState("");
    const [emptySubject, setEmptySubject] = useState(true);
    const [emptyMessage, setEmptyMessage] = useState(true);
    const [emptyEmailFrom, setEmptyEmailFrom] = useState(true);
    const { t } = useTranslation();

    const sendEmail = (e) => {
        e.preventDefault();

        if (e.target.subject.value.length > 0 && e.target.email_from.value.length > 0 && e.target.message.value.length > 0) {
            if (String(e.target.email_from.value)
                .toLowerCase()
                .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                emailjs.sendForm('service_pmqma3c', 'template_c0joh4s', e.target, 'Zn4dMLyhA5sclBi1w');
                props.onHide();
                setEmptySubject(true);
                setEmptyEmailFrom(true);
                setEmptyMessage(true);
            }
            else window.alert("email address wrong");
        }
    }

    return (
        <Modal
            animation={true}
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton style={{ borderTop: "4px solid #666", borderLeft: "4px solid #666", borderRight: "4px solid #666" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('help')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ borderLeft: "4px solid #666", borderRight: "4px solid #666" }}>
                {/* <div style={{ textAlign: "center" }}>
                    <button className="btn btn--stripe btn--radius" variant="primary" style={{ boxShadow: 'none', display: "initial" }}>Sing In</button>
                    <span style={{ marginLeft: "1%", marginRight: "1%" }}>or</span>
                    <button className="btn btn--stripe btn--radius" variant="primary" style={{ boxShadow: 'none', display: "initial" }}>Sing Up</button>
                </div>
                <Dropdown.Divider /> */}
                <Form onSubmit={sendEmail}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{t('email')} {emptyEmailFrom ? <BiStar style={{ color: '#666' }} /> : <></>}</Form.Label>
                        <Form.Control onChange={(e) => {
                            if (e.target.value.length > 0) {
                                if (String(e.target.value)
                                    .toLowerCase()
                                    .match(
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    )) setEmptyEmailFrom(false)
                                else setEmptyEmailFrom(true)
                            }
                            else setEmptyEmailFrom(true)
                        }} name="email_from" type="email" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{t('subject')} {emptySubject ? <BiStar style={{ color: '#666' }} /> : <></>}</Form.Label>
                        <Form.Select onChange={(e) => {
                            setSubject(e.target.value);
                            if (e.target.value.length > 0) setEmptySubject(false)
                            else setEmptySubject(true);
                        }}
                            value={subject}
                            defaultValue=""
                            name="subject"
                            placeholder="Enter theme"
                        >
                            <option value="">-</option>
                            <option value="Login or Sing up">Login/Sing up</option>
                            <option value="Radio stations">Radio stations</option>
                            <option value="Other">Other</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{t('message')} {emptyMessage ? <BiStar style={{ color: '#666' }} /> : <></>}</Form.Label>
                        <Form.Control onChange={(e) => {
                            if (e.target.value.length > 0) setEmptyMessage(false)
                            else setEmptyMessage(true);
                        }}
                            name="message" as="textarea" rows={5} />
                    </Form.Group>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                        <Form.Label>{t('fileSelect')}</Form.Label>
                        <Form.Control name="content" type="file" accept="image/*, video/*" multiple />
                    </Form.Group>

                    <button className="btn btn--stripe btn--radius" variant="primary" style={{ boxShadow: 'none' }}>
                        {t('sendHelp')}
                    </button>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "4px solid #666", borderBottom: "4px solid #666", borderLeft: "4px solid #666", borderRight: "4px solid #666" }}>
                <button onClick={() => {
                    props.onHide();
                    setEmptySubject(true);
                    setEmptyEmailFrom(true);
                    setEmptyMessage(true);
                }} className="btn btn--stripe btn--radius" variant="primary" style={{ boxShadow: 'none' }}>
                    {t('closeHelp')}
                </button>
            </Modal.Footer>
        </Modal >
    );
}

const Help = React.memo((props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { t } = useTranslation();

    return (
        <>
            <button className="btn btn-animation btn--radius" variant="primary" style={{ boxShadow: 'none' }} onClick={() => setModalShow(true)}>
                {t('help')}
            </button>

            <HelpPanel
                show={modalShow}
                onHide={() => setModalShow(false)}
                style={{ border: "4px solid #666" }}
            />
        </>
    );
})

Help.prototype = {
}

export default Help;