import React from "react";
import "../../Tools/Favorite/Favorite.css";
// import inFavorite from "../../image/in-favorite.svg";
// import musicReady from "../../image/musicReady.gif"
import { Image } from "react-bootstrap";
import { Translation } from "react-i18next";
import errImage from "../../image/image-error.svg";
import "../../css-files/RadioListButton.css"

import Favorite from "../../image/favorite.svg"
import inFavorite from "../../image/in-favorite.svg"
import hoverFavorite from "../../image/hover-favorite.svg"

export default function InfoPanel({ handleClick, radioInfo, favorite }) {
    const onImageError = (e) => {
        e.target.src = errImage;
    }

    const ChangeImageFavorite = () => {
        return (
            <button className="favorite">
                {
                    favorite?.includes(radioInfo?.Id) ?
                        (
                            <>
                                <Image style={{ height: "4vh" }} src={Favorite} className="down-image" onClick={handleClick} />
                                <Image src={inFavorite} style={{ height: "4vh" }} onClick={handleClick} />
                            </>
                        )
                        :
                        (
                            <>
                                <Image src={hoverFavorite} style={{ height: "4vh" }} className="upper-image" onClick={handleClick} />
                                <Image style={{ height: "4vh" }} src={Favorite} onClick={handleClick} />
                            </>
                        )
                }
            </button>
        )
    }

    return (
        <>
            <ChangeImageFavorite />
            <div className="infopanel-text" key="infopanel" id="infopanel">
                <div key="infopanel-div" id="infopanel-div">
                    <div key="infopanel-description" id="infopanel-description" style={{ margin: "1% auto auto 0%" }}>
                        <div className="infopanel-image" key="infopanel-image-div" id="infopanel-image-div">
                            <Image key="infopanel-image" onError={onImageError} style={{ width: "18vw", objectFit: "contain", aspectRatio: "1/1", filter: "drop-shadow(black 0px 0px 3px)" }} src={radioInfo?.Image} />
                        </div>

                        <Translation>
                            {
                                (t) => <div key="infopanel-descriptionName" id="infopanel-descriptionName" style={{ fontSize: "40px" }}>{t(radioInfo.Name)}</div>
                            }
                        </Translation>
                        <Translation>
                            {
                                (t) => <div key="infopanel-descriptionCountry" id="infopanel-descriptionCountry" style={{ fontSize: "25px" }}>{t(radioInfo.Country)}</div>
                            }
                        </Translation>
                        <Translation>
                            {
                                (t) => <div key="infopanel-descriptionGenre" id="infopanel-descriptionGenre" style={{ fontSize: "18px" }}>{t(radioInfo.Genre)}</div>
                            }
                        </Translation>
                    </div>
                </div>
                {/* {JSON.parse(localStorage.getItem('isPause')) === false ?
                <div style={{ position: "relative", overflow: "hidden", top: "60vh" }}>
                    <Image src={musicReady} style={{ width: "35vh", margin: "-45px -100px" }} />
                </div>
                :
                <></>
            } */}
            </div>
        </>
    );
}